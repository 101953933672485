import { createStore, combineReducers, compose, applyMiddleware } from "redux"
import thunk from "redux-thunk"

import loginReducer from "./reducers/loginReducer"
import headerReducer from "./reducers/headerReducer"
import searchReducer from "./reducers/searchReducer"
import cartReducer from "./reducers/cartReducer"
import cardReducer from "./reducers/cardReducer"
import adminReducer from "./reducers/adminReducer"
import errorHandlerReducer from "./reducers/errorHandlerReducer"
import successHandlerReducer from "./reducers/successHandlerReducer"
import cartMiddleware from "./cartMiddleware";

export default function configureStore() {
  const composeEnhancers =
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

  const rootReducer = combineReducers({
    login: loginReducer,
    header: headerReducer,
    search: searchReducer,
    cart: cartReducer,
    cards: cardReducer,
    admin: adminReducer,
    error: errorHandlerReducer,
    success: successHandlerReducer
  })

  return createStore(rootReducer, composeEnhancers(applyMiddleware(thunk, cartMiddleware)));
}
