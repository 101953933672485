import React, { useEffect, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { Trans } from "react-i18next"
import "./OrderDishPage.scss"
import calendarIcon from "../../images/dish/calendar_icon.svg"
import morningIcon from "../../images/dish/morning_icon.svg"
import locationIcon from "../../images/dish/location_icon.svg"
import magnifierIcon from "../../images/dish/magnifier_icon.svg"
import checkMark from "../../images/dish/check_cross_mark.svg"
import selectedIcon from "../../images/dish/selected_icon.svg"
import pdfIcon from "../../images/dish/pdf_icon.svg"
import questionOctagonIcon from "../../images/dish/question_octagon_icon.svg"
import commentsIcon from "../../images/dish/comments_icon.svg"
import packageIcon from "../../images/dish/package_icon.svg"
import closeGray from "../../images/header/close-gray-icon.svg"
import dishTagRed from "../../images/dish_logo_full.svg"
import optionIcons from "../OptionIconsImages"
import ErrorWindow from "../ErrorWindow"
import ConfirmationWindow from "../ConfirmationWindow"
import moment from "moment"
import "moment/locale/he"
import {
    saveTempItemToOrder,
    incCount
} from "../../redux/actions/cartActions.js"
import { connect } from "react-redux"
import LoadingPage from "../LoadingPage"
import imageFiller from "../../images/image_filler_icon.svg"
import NotifyWindow from "../NotifyWindow"
import { setError } from "../../redux/actions/errorHandlerActions"
import ItemImageHelper from "../helpers/ItemImageHelper"
import QuantityCounter from "./QuantityCounter"
import { getMealItemUserInfo, saveItemToTempStorage } from "../main/mainCrud"
import { mealTypes } from "../mealTypes"
import {
    formatDate,
    formatDateForApi,
    formatDateWithWeekDay
} from "../commonFunctions"
import PreviewWindow from "../PreviewWindow";

function OrderDishPage({
    editData,
    confirmEditOrder,
    submitData,
    removeItemId,
    goToOrder,
    itemID,
    isStore,
    supplierDocLink,
    mealID,
    userID,
    date,
    incCount,
    saveTempItemToOrder,
    paymentOptions,
    subsidyLimit,
    deliveryTime,
    ...props
}) {
    const { t, i18n } = useTranslation("main");
    const [itemData, setItemData] = useState({
        item: null,
        isLoading: true
    })
    const [orderIdItemData, setOrderIdItemData] = useState({
        isLoading: false
    })
    const [note, setNote] = useState(!editData ? "" : editData.OrderItemNotes)
    const [showMediaView, setShowMediaView] = useState(false);
    const [quantity, setQuantity] = useState(
        !editData ? 1 : editData.OrderItemQuantity
    )
    const [selected, setSelected] = useState(
        !editData ? [] : editData.SelectedOptions
    )
    const [error, setError] = useState({ show: false, message: "" })
    const [showIgnoredGroups, setShowIgnoredGroups] = useState(false)
    const [showCancelConfirmation, setShowCancelConfirmation] = useState(false)
    const [showNewOrderDialog, setShowNewOrderDialog] = useState(false)
    const [showSuccessDialog, setShowSuccessDialog] = useState(false)

    const infoWrapper = useRef(null)
    const [infoWrapperHeight, setInfoWrapperHeight] = useState(null)
    const [previousPaidOptionsSum, setPreviousPaidOptionsSum] = useState(0);

    const { innerWidth: width } = window

    useEffect(() => {
        if (!itemData.isLoading && infoWrapper.current) {
            setInfoWrapperHeight(infoWrapper.current.scrollHeight - 30)
        }
    }, [infoWrapper.current, itemData.isLoading])

    useEffect(() => {
        if (editData) {
            const initialSum = editData.SelectedOptions.reduce(
                (sum, option) => sum + (option.ClientPrice || 0),
                0
            );
            setPreviousPaidOptionsSum(initialSum);
        }
    }, [editData]);

    useEffect(() => {
        fetchMealItemUserInfo()
    }, [])

    if (itemData.isLoading || (!!orderIdItemData && orderIdItemData.isLoading)) {
        return <LoadingPage />
    }

    const summaryPrice = selected.reduce(
        (sum, option) => sum + option.ClientPrice,
        0
    )

    const ignoredGroups = itemData.item.OptionGroups.filter(
        group => group.IsMandatory
    ).filter(
        optionGroup =>
            optionGroup.OptionGroup.Options.filter(
                option =>
                    selected.find(
                        opt => opt.id === option.id && opt.ItemOptionId === optionGroup.id
                    ) !== undefined
            ).length === 0
    )
    const quantityIsValid =
        itemData.item.MealType === 4
            ? true
            : quantity > 0 && quantity <= itemData.item.AvilableQuantity

    const commentIsTooLong = note.length > 70

    const selectedGroups = itemData.item.OptionGroups.map(optionGroup => ({
        OptionGroupID: optionGroup.id,
        Lable: optionGroup.Lable,
        Min: optionGroup.OptionGroup.Min,
        IsMandatory: optionGroup.IsMandatory,
        selectedSummary: optionGroup.OptionGroup.Options.reduce((sum, option) => {
            if (
                selected.find(
                    opt => opt.id === option.id && opt.ItemOptionId === optionGroup.id
                ) !== undefined
            ) {
                sum++
            }
            return sum
        }, 0)
    }))

    const groupsWithoutMinimum = selectedGroups.filter(
        group =>
            (group.selectedSummary !== 0 || group.IsMandatory) &&
            group.Min > group.selectedSummary
    )

    function validate() {
        if (commentIsTooLong) {
            setError({
                show: true,
                message: t("order_item.error_window.note_is_too_long")
            })
        } else if (ignoredGroups.length !== 0) {
            setShowIgnoredGroups(true)
            setError({
                show: true,
                message: (
                    <>
                        <Trans t={t} i18nKey="order_item.error_window.options_required">
                            In order to continue
                            <b> you must select</b>
                        </Trans>
                        {ignoredGroups.map((ignoredGroup, index) => (
                            <b key={ignoredGroup.Lable}>
                                {index !== 0 && ","} {ignoredGroup.Lable}
                            </b>
                        ))}
                    </>
                )
            })
        } else if (groupsWithoutMinimum.length !== 0) {
            setShowIgnoredGroups(true)
            setError({
                show: true,
                message: (
                    <>
                        {t("order_item.error_window.min_number_is_not_selected")}
                        {groupsWithoutMinimum.map((group, index) => (
                            <b key={group.Lable}>
                                {index !== 0 && ","} {group.Lable}
                            </b>
                        ))}
                    </>
                )
            })
        } else if (!quantityIsValid) {
            setError({
                show: true,
                message: t("order_item.error_window.quantity_invalid")
            })
        } else {
            if (editData) {
                const data = {
                    notes: note,
                    options: selected,
                    quantity: quantity
                }

                if (previousPaidOptionsSum !== summaryPrice) {
                    setError({
                        show: true,
                        message: t("order_item.error_window.paid_options_changed")
                    });
                    return;
                }

                submitData(data)
                    .then(() => {
                        // setShowSuccessDialog(true)
                        confirmEditOrder(data)
                        removeItemId()
                    })
                    .catch(error => {
                        console.error(error)
                        props.setError(
                            error,
                            t("order_item.api_errors.failed_to_edit_order")
                        )
                    })
            } else {
                if (subsidyLimit) {
                    saveToCart(false)
                } else {
                    setShowNewOrderDialog(true)
                }
            }
        }
    }

    function handleChangeNote(event) {
        const { value } = event.target
        if (commentIsTooLong) {
            setError({
                show: true,
                message: t("order_item.error_window.note_is_too_long")
            })
        }
        setNote(value)
    }

    function saveToCart(backToMeals) {
        const orderItem = {
            ItemID: itemID,
            SelectedOptions: selected,
            Notes: note,
            Status: null,
            MealID: mealID,
            MealDeliveryTimeId: deliveryTime?.Id,
            Cost: 0,
            CustomerPatment: 0,
            ClientPayment: itemData.item.ItemPrice,
            OrderToDate: date,
            SupplierID: itemData.item.SupplierID,
            Quantity: quantity,
            ItemName: itemData.item.ItemName,
            ItemImages: itemData.item.ItemPhoto,
            MealTypeName: t(
                `order_item.meal_type.${mealTypes[itemData.item.MealType - 1]}`
            )
        }
        saveTempMealItemToOrder(orderItem, backToMeals)
    }

    function selectOption(option, optionGroup) {
        if (
            optionGroup.OptionGroup.Max === 0 ||
            selectedGroups.find(group => group.OptionGroupID === optionGroup.id)
                .selectedSummary < optionGroup.OptionGroup.Max
        ) {
            setSelected([
                ...selected,
                {
                    ...option,
                    OptionGroupName: optionGroup.OptionGroup.Name,
                    Icon: optionGroup.OptionGroup.Icon,
                    ClientPrice: optionGroup.IsFree ? 0 : option.ClientPrice,
                    ItemOptionId: optionGroup.id
                }
            ])
        }
    }
    function fetchMealItemUserInfo() {
        getMealItemUserInfo(
            itemID,
            mealID,
            userID,
            formatDateForApi(date),
            props.cart[userID] ? props.cart[userID].orderID : undefined,
            editData && !editData.OrderItemID && editData.Id
                ? editData.Id
                : undefined,
            editData && editData.OrderItemID && editData.Id ? editData.Id : undefined
        )
            .then(({ data }) => {
                setItemData({ item: data, isLoading: false })
                const formattedSelected = []
                selected.forEach(option => {
                    const optionGroup = data.OptionGroups.find(
                        group => group.OptionGroup.id === option.OptionGroupID
                    )
                    if (optionGroup) {
                        const isFree = optionGroup.IsFree
                        formattedSelected.push({
                            ...option,
                            OptionGroupName: optionGroup.OptionGroup.Name,
                            Icon: optionGroup.OptionGroup.Icon,
                            ClientPrice: isFree ? 0 : option.ClientPrice,
                            CustomerPrice: isFree ? 0 : option.CustomerPrice,
                            Price: isFree ? 0 : option.Price
                        })
                    }
                })
                setSelected(formattedSelected)
            })
            .catch(error => {
                console.error(error)
                props.setError(
                    error,
                    t("order_item.api_errors.failed_to_get_meal_item_user_info")
                )
            })
    }

    function saveTempMealItemToOrder(orderItem, backToMeals) {
        setOrderIdItemData({ isLoading: true })
        saveItemToTempStorage(
            userID,
            props.cart[userID] ? props.cart[userID].orderID : undefined,
            orderItem,
            deliveryTime?.Id
        )
            .then(({ data }) => {
                saveTempItemToOrder(userID, data.Id)
                incCount(userID, orderItem.Quantity, data.EmployeeSum)
                setOrderIdItemData({ isLoading: false })
                if (backToMeals) {
                    removeItemId()
                } else {
                    goToOrder()
                }
            })
            .catch(error => {
                setOrderIdItemData({ isLoading: false })
                console.error(error)
                const errorMessage = error?.response?.data?.Message || error.message;
                if (errorMessage === "Order Item Limit" || errorMessage === "Order item quantity is more than available") {
                    props.setError(error, t("order_item.api_errors.there_is_an_order_for_this_day_in_Cart"))
                } else if (errorMessage === "Invalid rating time") {
                    props.setError(error, t("order_item.api_errors.time_is_over"))
                } else {
                    props.setError(error, t("order_item.api_errors.failed_to_save_to_cart"))
                }
            })
    }

    return (
        <>
            {
                showMediaView && (
                    <PreviewWindow
                        show={showMediaView}
                        url={itemData?.item?.SupplierDocLink}
                        onConfirm={() => {
                            setShowMediaView(false)
                        }}
                        onClose={() => setShowMediaView(false)}
                    />
                )
            }
            <div className="order-item-info-wrapper" dir={i18n.dir()}>
                <ConfirmationWindow
                    show={showCancelConfirmation}
                    icon={questionOctagonIcon}
                    primaryText={t("order_item.cancel_confirmation.primary")}
                    secondaryText={t("order_item.cancel_confirmation.secondary")}
                    confirmText={t("order_item.cancel_confirmation.continue")}
                    cancelText={t("order_item.cancel_confirmation.cancel")}
                    onConfirm={() => setShowCancelConfirmation(false)}
                    onCancel={() => removeItemId()}
                    onClose={() => setShowCancelConfirmation(false)}
                />
                <ConfirmationWindow
                    show={showNewOrderDialog}
                    icon={packageIcon}
                    primaryText={t("order_item.new_order_dialog.primary")}
                    cancelText={t("order_item.new_order_dialog.continue")}
                    confirmText={t("order_item.new_order_dialog.cancel")}
                    boothWhite="true"
                    onCancel={() => {
                        saveToCart(true)
                    }}
                    onConfirm={() => {
                        saveToCart(false)
                    }}
                    onClose={() => setShowNewOrderDialog(false)}
                />
                <NotifyWindow
                    show={showSuccessDialog}
                    primaryText={t("order_item.success_window.primary")}
                    confirmText={t("order_item.success_window.continue")}
                    onConfirm={() => removeItemId()}
                    onClose={() => setShowSuccessDialog(false)}
                />
                <div className="order-row__cancel-button cancel-header ">
                    <img id="small-dish-tag" src={dishTagRed} alt="Dish tag" />
                    <img
                        className="order-row__cancel-button__close-icon"
                        src={closeGray}
                        alt="Close icon"
                        onClick={() => setShowCancelConfirmation(true)}
                    />
                    <div className="img-partner">{t("order_item.cancel")}</div>
                </div>
                <div className="info-line">
                    <img src={calendarIcon} alt="calendar" />
                    <div className="img-partner">
                        {moment(date).isSame(new Date(), "day")
                            ? formatDate(date) + " " + t("days.today")
                            : formatDateWithWeekDay(date)}
                    </div>
                    <div className="meal-type">
                        <img src={morningIcon} alt="sun" />
                        {t(`order_item.meal_type.${mealTypes[itemData.item.MealType - 1]}`)}
                    </div>
                </div>
                <div className="info-line">
                    <img src={locationIcon} alt="location" />
                    <div className="img-partner">{itemData.item.LocationName}</div>
                </div>
                <div
                    className="item-photo-wrapper"
                    style={{ maxHeight: infoWrapperHeight }}
                >
                    <div className="image-elements-wrapper">
                        {itemData.item.SupplierDocLink && (
                            <div className="certificate-btn-wrapper">
                                <a
                                    rel="noopener noreferrer"
                                    onClick={() => {
                                        if (width < 1024) {
                                            setShowMediaView(true);
                                        } else {
                                            if (window.cordova) {
                                                window.open(itemData.item.SupplierDocLink, "_system")
                                            } else {
                                                window.open(itemData.item.SupplierDocLink, "_blank")
                                            }
                                        }
                                    }}
                                >
                                    <div className="certificate-btn">
                                        <img
                                            src={pdfIcon}
                                            className="certificate-btn__icon"
                                            alt="pdf"
                                        />
                                        {t("order_item.certificate")}
                                    </div>
                                </a>
                            </div>
                        )}
                        <div className="image-tags-wrapper">
                            {itemData.item.ImageTags.sort((a, b) => {
                                if (b.Priority < a.Priority) {
                                    return -1
                                }
                                if (b.Priority > a.Priority) {
                                    return 1
                                }
                                return 0
                            }).map(tag => (
                                <div key={tag.TagID} className="image-tag">
                                    {tag.Name}
                                </div>
                            ))}
                        </div>
                    </div>
                    {itemData.item.ItemPhoto && itemData.item.ItemPhoto.length > 5 ? (
                        <picture className="meal-img">
                            <source
                                srcSet={ItemImageHelper.larg_webp(itemData.item.ItemPhoto)}
                                media="(min-width:1000px)"
                                type="image/webp"
                            />
                            <source
                                srcSet={ItemImageHelper.larg_jpg(itemData.item.ItemPhoto)}
                                media="(min-width:1000px)"
                                type="image/jpg"
                            />
                            <source
                                srcSet={ItemImageHelper.small_webp(itemData.item.ItemPhoto)}
                                type="image/webp"
                            />
                            <source
                                srcSet={ItemImageHelper.small_jpg(itemData.item.ItemPhoto)}
                                type="image/jpg"
                            />
                            <img
                                src={ItemImageHelper.larg_jpg(itemData.item.ItemPhoto)}
                                alt="meal"
                                className="meal-img"
                            />
                        </picture>
                    ) : (
                        <img src={imageFiller} alt="meal" className="meal-img-filler" />
                    )}
                </div>
                <div className="item-info-wrapper" ref={infoWrapper}>
                    <div className="content-wrapper">
                        <ErrorWindow
                            show={error.show}
                            message={error.message}
                            onClose={() => setError({ ...error, show: false })}
                        />
                        <div>
                            <div className="meal-name">{itemData.item.ItemName}</div>
                            <div className="meal-description">
                                {itemData.item.ItemDesctiption}
                            </div>
                            <div className="tag-wrapper">
                                {itemData.item.Tags.sort((a, b) => {
                                    if (b.Priority < a.Priority) {
                                        return -1
                                    }
                                    if (b.Priority > a.Priority) {
                                        return 1
                                    }
                                    return 0
                                }).map(tag => (
                                    <div key={tag.TagID} className="tag">
                                        <img src={tag.IconURL} alt="tag" />
                                        {tag.Name}
                                    </div>
                                ))}
                                {!editData && !subsidyLimit && (
                                    <QuantityCounter
                                        quantityIsValid={quantityIsValid}
                                        quantity={quantity}
                                        setQuantity={setQuantity}
                                        limit={itemData.item.AvilableQuantity}
                                    />
                                )}
                            </div>
                            <div className="divider" />
                            <div className="supplier-line">
                                <img src={magnifierIcon} alt="supplier" />
                                <div className="meal-supplier">{itemData.item.SupplierName}</div>
                            </div>
                            <div className="divider" />
                        </div>
                        {
                            !isStore && (
                                <div className="option-groups-wrapper">
                                    {itemData.item.OptionGroups.sort((a, b) => {
                                        if (b.Order < a.Order) {
                                            return -1
                                        }
                                        if (b.Order > a.Order) {
                                            return 1
                                        }
                                        return 0
                                    }).map((optionGroup, index) => (
                                        <div key={optionGroup.id}>
                                            {index !== 0 && <div className="divider" />}
                                            <div
                                                className={
                                                    showIgnoredGroups &&
                                                        ((optionGroup.IsMandatory &&
                                                            ignoredGroups.find(
                                                                group => group.id === optionGroup.id
                                                            ) !== undefined) ||
                                                            groupsWithoutMinimum.find(
                                                                group => group.OptionGroupID === optionGroup.id
                                                            ) !== undefined)
                                                        ? "option-group option-group--error"
                                                        : "option-group"
                                                }
                                            >
                                                <div className="option-group__label">
                                                    <img
                                                        src={optionIcons[Number(optionGroup.OptionGroup.Icon)]}
                                                        alt="meal"
                                                    />
                                                    <div className="img-partner">
                                                        {optionGroup.Lable}{" "}
                                                        <span className="option-select-text">
                                                            {optionGroup.OptionGroup.Max !== 0 &&
                                                                `${selected.filter(
                                                                    opt =>
                                                                        opt.OptionGroupID === optionGroup.OptionGroup.id
                                                                ).length
                                                                }/${optionGroup.OptionGroup.Max}`}
                                                            {optionGroup.OptionGroup.IsMandatory === true &&
                                                                " (חובה) "}
                                                        </span>
                                                    </div>
                                                </div>
                                                <div className="option-group__options">
                                                    {optionGroup.OptionGroup.Options.map(option => (
                                                        <div key={option.id} className="option-wrapper">
                                                            <div
                                                                className={
                                                                    selected.find(
                                                                        opt =>
                                                                            opt.id === option.id &&
                                                                            opt.ItemOptionId === optionGroup.id
                                                                    )
                                                                        ? "option option--selected"
                                                                        : "option"
                                                                }
                                                                onClick={() =>
                                                                    selected.find(
                                                                        opt =>
                                                                            opt.id === option.id &&
                                                                            opt.ItemOptionId === optionGroup.id
                                                                    )
                                                                        ? setSelected(
                                                                            selected.filter(
                                                                                opt =>
                                                                                    !(
                                                                                        opt.id === option.id &&
                                                                                        opt.ItemOptionId === optionGroup.id
                                                                                    )
                                                                            )
                                                                        )
                                                                        : selectOption(option, optionGroup)
                                                                }
                                                            >
                                                                <img src={selectedIcon} alt="selected" />
                                                                <span className="option__text">
                                                                    {option.Name}{" "}
                                                                    {!optionGroup.IsFree &&
                                                                        option.ClientPrice !== 0 &&
                                                                        t("currency") + `${option.ClientPrice}`}
                                                                </span>
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            )
                        }

                        <div className="divider" />
                        <div>
                            <div className="note__label">
                                <img src={commentsIcon} alt="note" />
                                <div className="img-partner">{t("order_item.note_label")}</div>
                            </div>
                            <div>
                                <textarea
                                    placeholder={t("order_item.note_placeholder")}
                                    className={commentIsTooLong ? "--error" : ""}
                                    value={note}
                                    onChange={handleChangeNote}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="order-row">
                        <div
                            className="order-row__cancel-button"
                            onClick={() => setShowCancelConfirmation(true)}
                        >
                            <img
                                className="order-row__cancel-button__close-icon"
                                src={closeGray}
                                alt="Close icon"
                            />
                            <div className="img-partner">{t("order_item.cancel")}</div>
                        </div>
                        <div className="order-row__order" onClick={validate}>
                            <img src={checkMark} alt="check" />
                            <div className="img-partner">
                                {editData
                                    ? t("order_item.change_the_order")
                                    : t("order_item.order_the_dish")}
                            </div>
                            {summaryPrice !== 0 && (
                                <div className="order-row__summary-price">{t("currency")}{summaryPrice}</div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

function mapStateToProps(state) {
    return {
        cart: state.cart
    }
}

const mapDispatchToProps = {
    saveTempItemToOrder,
    incCount,
    setError
}
export default connect(mapStateToProps, mapDispatchToProps)(OrderDishPage)
