import { Types } from "../actionTypes"

const loadCartFromStorage = () => {
    try {
        const savedCart = JSON.parse(localStorage.getItem("cart"));
        if (savedCart && Date.now() - savedCart.timestamp < 15 * 60 * 1000) {
            return savedCart.data;
        }
    } catch (error) {
        console.error("loading from localStorage error:", error);
    }
    return {};
};

const initialState = loadCartFromStorage();

export default function cartReducer(state = initialState, action) {
    let newState;

    switch (action.type) {
        case Types.SEND_ITEM_TO_TEMP_ORDER:
            newState = {
                ...state,
                [action.data.userID]: {
                    ...state[action.data.userID],
                    orderID: action.data.orderID
                }
            };
            break;

        case Types.CLEAR_CART:
            newState = {
                ...state,
                [action.data]: {
                    count: 0,
                    employeeSum: 0
                }
            };
            break;

        case Types.INCREASE_COUNT:
            newState = {
                ...state,
                [action.data.userId]: {
                    ...state[action.data.userId],
                    count: state[action.data.userId]?.count
                        ? state[action.data.userId].count + action.data.quantity
                        : 1,
                    employeeSum: action.data.employeeSum
                }
            };
            break;

        case Types.DECREASE_COUNT:
            newState = {
                ...state,
                [action.data]: {
                    ...state[action.data],
                    count: Math.max(0, state[action.data]?.count - 1 || 0), 
                    employeeSum: action.data.employeeSum
                }
            };
            break;

        default:
            return state;
    }

    localStorage.setItem("cart", JSON.stringify({ data: newState, timestamp: Date.now() }));

    return newState;
}