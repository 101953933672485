const cartMiddleware = store => next => action => {
    const result = next(action);

    if (["SEND_ITEM_TO_TEMP_ORDER", "INCREASE_COUNT", "DECREASE_COUNT", "CLEAR_CART"].includes(action.type)) {
        const state = store.getState();
        localStorage.setItem("cart", JSON.stringify({ data: state.cart, timestamp: Date.now() }));
    }

    return result;
};

export default cartMiddleware;