import React, {useEffect, useRef, useState} from "react"
import {useTranslation} from "react-i18next"
import Footer from "../footer/Footer"
import OrderList from "./OrderList"
import cartIcon from "../../images/cart_icon.svg"
import warningIcon from "../../images/warning_icon_circle.svg"
import "./OrderPage.scss"
import { connect, useDispatch, useSelector} from "react-redux"
import * as cartActions from "../../redux/actions/cartActions"
import * as cardActions from "../../redux/actions/cardActions"
import * as errorActions from "../../redux/actions/errorHandlerActions"
import NotifyWindow from "../NotifyWindow"
import {withRouter} from "react-router"
import {toFixedNumber, useCurrentWidth} from "../commonFunctions"
import {getSummary, sendOrder, deleteItem, changeItem, changeCombo, getLimitBalance, getBudget, submitOrder, getCustomerCreditAvailable} from "./orderCrud"
import TranzilaWindow from "./TranzilaWindow"
import OrderDishPage from "../dish/OrderDishPage"
import OrderComboDishPage from "../dish/OrderComboDishPage"
import LoadingPage from "../LoadingPage"
import {Redirect} from "react-router-dom"
import moneyIcon from "../../images/payment-icons/money_icon.svg"
import dishWalletIcon from "../../images/payment-icons/dish_wallet_icon.svg"
import companyBadgetIcon from "../../images/payment-icons/company_badget_icon.svg"
import cardPaymentIcon from "../../images/payment-icons/card_payment_icon.svg"
import PaymentAccordion from "./PaymentAccordion"
import OrderConfirmationWindow from "./OrderConfirmationWindow"
import { Trans } from "react-i18next"
import LogoFooter from "../main/LogoFooter"
import { setSearchText, setSearchUser } from "../../redux/actions/searchActions"

function OrderPage(props) {
    // translation function
    const {t} = useTranslation("main")
    const footer = useRef(null)
    const [footerHeight, setFooterHeight] = useState("")

    // user info
    const userInfo = useSelector(state => state.login.userInfo)
    const isRoleAdmin = userInfo.Role === "Admin"
    const selectedCustomer = useSelector(state => state.admin.selectedCustomer)
    const isRoleDepartmentUser = userInfo.Role === "Department User"
    // Id of the selected user
    const userId = useSelector(state => state.search.searchUser.UserID)

    // user cards
    const cards = useSelector(state => state.cards)
    // user cart
    const cart = useSelector(state => state.cart[userId])
    const {innerWidth: width} = window

    // dispatcher for redux actions
    const dispatch = useDispatch()

    const [paymentOptions, setPaymentOptions] = useState([])
    const [hideTotal, setHideTotal] = useState([])
    const [dailyBudgets, setDailyBudgets] = useState([])

    const [selectedAmounts, setSelectedAmounts] = useState({
        1: "",
        2: "",
        3: "",
        4: ""
    })

    const [orders, setOrders] = useState({data: [], isLoading: false})
    const [employeeChargeAmount, setEmployeeChargeAmount] = useState(0)
    const [employeeChargeBudgetAmount, setEmployeeChargeBudgetAmount] = useState(0)
    const [totalChargeAmount, setTotalChargeAmount] = useState(0)
    const [limitBalance, setLimitBalance] = useState(0)

    const [fetchSummaryCount, setFetchSummaryCount] = useState(1)

    const [editItem, setEditItem] = useState(null)

    const [showSuccessDialog, setShowSuccessDialog] = useState(false)
    const [showTranzilaWindow, setShowTranzilaWindow] = useState(false)
    const [showConfirmationWindow, setShowConfirmationWindow] = useState(false)
    const [isPaymentFailed, setIsPaymentFailed] = useState(false)
    const [paymentFailedText, setPaymentFailedText] = useState(false)

    const [selectedCard, setSelectedCard] = useState(
        !isRoleDepartmentUser && cards.length > 0
            ? cards[0]
            : {
                creditCardToken: null,
                expirationMonth: null,
                expirationYear: null,
                isSelectorHidden: true
            }
    )

    const [selectedPaymentMethods, setSelectedPaymentMethods] = useState([])

    const [isOrderSubmitting, setIsOrderSubmitting] = useState(false)
    // const [isCvvInputInProgress, setIsCvvInputInProgress] = useState(false)
    // const [isCvvInvalid, setIsCvvInvalid] = useState(false)

    const [remainingAmount, setRemainingAmount] = useState(0)
    const [selectedAmountsSum, setSelectedAmountsSum] = useState(0)
    const [isSelectedAmountInvalid, setIsSelectedAmountInvalid] = useState(false)
    const [showAmountInvalidWindow, setShowAmountInvalidWindow] = useState(false)

    function handleChangeAmount(id, value) {
        //dailybudgets
        if (id === 5) {
            setDailyBudgets(value);
            return;
        } 
        var strValue = value.length ? Math.abs(parseFloat(value)) : "";
        const state = {
            ...selectedAmounts,
            [id]: `${strValue}`
        }
        
        const selectedAmount = Object.values(state).reduce((sum, el) => sum + +el, 0)
        const remainingAmount = toFixedNumber(employeeChargeAmount - selectedAmount, 4)
        const isRemainingAmountInvalid = remainingAmount < 0

        if (id === 1) {
            if (strValue === employeeChargeAmount) {
                setHideTotal(true);
            } else {
                setHideTotal(false);
            }
        }
        setIsSelectedAmountInvalid(isRemainingAmountInvalid)
        setSelectedAmounts(state)
        setSelectedAmountsSum(toFixedNumber(selectedAmount, 4))
        setRemainingAmount(isRemainingAmountInvalid ? 0 : remainingAmount)
    }

    function setPaymentOptionAmounts(walletBalance, budgetAmount, companyAmount, isSubsidyAvailable, hideBudget, dailyBudgets) {
        setPaymentOptions([
            {
                id: 1,
                name: t("order.payment_options.employer_budget"),
                icon: moneyIcon,
                showInfoIcon: true,
                dailyBudgets: dailyBudgets,
                amount: budgetAmount,
                isHidden: !budgetAmount,
                isHideBudget: hideBudget
            },
            {
                id: 2,
                name: t("order.payment_options.dish_wallet"),
                icon: dishWalletIcon,
                showInfoIcon: true,
                amount: walletBalance,
                isHidden: !userInfo.AllowWallet || !walletBalance
            },
            {
                id: 3,
                name: t("order.payment_options.company_account"),
                icon: companyBadgetIcon,
                amount: companyAmount,
                isHidden: userInfo.UserID === userId ? (!userInfo.CompanyCreditSupport || !companyAmount) : !companyAmount
            },
            {
                id: 4,
                name: t("order.payment_options.credit_card"),
                icon: cardPaymentIcon,
                cards: cards,
                onOpenTranzilla: () => setShowTranzilaWindow(true),
                isHidden: isSubsidyAvailable
            }
        ])
    }

    function fetchSummary() {
        setOrders({...orders, isLoading: true})
        let summaryData;
        let dailyBudgets;
        let limitBalance = 0;
        let budgetBalance = 0;
        let customerCredit = false;
        const promises = [
            getSummary(userId, cart.orderID)
                .then(({data}) => summaryData = data)
                .catch(error => {
                    console.error(error)
                    dispatch(
                        errorActions.setError(
                            error,
                            t("order.api_errors.failed_to_get_cart_info")
                        )
                    )
                }),
            getLimitBalance(userId, cart.orderID)
                .then(({data}) => limitBalance = data.result)
                .catch(error => {
                    console.error(error)
                    dispatch(
                        errorActions.setError(
                            error,
                            t("order.api_errors.failed_to_get_company_credit_amount")
                        )
                    )
                }),
            getBudget(userId, cart.orderID)
                .then(({ data }) => {
                    budgetBalance = data.Budget
                    dailyBudgets = data.DailyBudgets
                })
                .catch(error => {
                    console.error(error)
                    dispatch(
                        errorActions.setError(
                            error,
                            t("order.api_errors.failed_to_get_budget_amount")
                        )
                    )
                }),
            getCustomerCreditAvailable(userId, cart.orderID)
                .then(({data}) => {

                    customerCredit = data;
                })
                .catch(error => {
                    console.error(error)
                    dispatch(
                        errorActions.setError(
                            error,
                            t("order.api_errors.failed_to_get_company_credit_amount")
                        )
                    )
                }),
        ]
        Promise.allSettled(promises).then(() => {
            setOrders({data: summaryData?.Items, isLoading: false})
            setEmployeeChargeAmount(summaryData?.EmployeeSum)
            setRemainingAmount(summaryData?.EmployeeSum)
            setTotalChargeAmount(summaryData?.Sum)
            var hideBudget = budgetBalance && summaryData.Sum <= budgetBalance && !summaryData?.IsPriceDisplayed;
            setHideTotal(hideBudget);
            setPaymentOptionAmounts(summaryData?.WalletBalance,
                budgetBalance,
                userInfo.CompanyCreditSupport || customerCredit ? limitBalance : 0,
                summaryData?.IsSubsidyAvailable, hideBudget,
                dailyBudgets)
        })
        
    }

    // function getTotalLimitBalance() {
    //     getLimitBalance(userId, cart.orderID)
    //         .then(({data}) => {
    //             setLimitBalance(data)
    //         })
    //         .catch(error => {
    //             console.error(error)
    //             dispatch(
    //                 errorActions.setError(
    //                     error,
    //                     t("order.api_errors.failed_to_get_cart_info")
    //                 )
    //             )
    //         })
    // }

    function handleSendOrder() {
        if (isSelectedAmountInvalid || remainingAmount !== 0) { 
            setShowAmountInvalidWindow(true)
            return
        }
        
        setIsOrderSubmitting(true)

        const budget = +selectedAmounts[1]
        const wallet = +selectedAmounts[2]
        const customerCredit = +selectedAmounts[3]
        const creditCard = +selectedAmounts[4]

        const data = {}
        
        if (budget) {
            data.budget = budget
            data.dailyBudgets = dailyBudgets
        }
        if (wallet) {
            data.wallet = wallet
        }
        if (customerCredit) {
            data.customerCredit = customerCredit
        }
        if (creditCard) {
            data.creditCard = creditCard
            data.ccExpDate = `${selectedCard.expirationMonth}${selectedCard.expirationYear}`
            data.ccToken = selectedCard.creditCardToken
        }

        let allDailyBudgets = paymentOptions?.find(x => x.id === 1)?.dailyBudgets;

        data.dailyBudgets = allDailyBudgets;

        if (allDailyBudgets && allDailyBudgets?.length > data.dailyBudgets?.length) {
            const existingDates = new Set(data.dailyBudgets?.map(b => b.date));

            allDailyBudgets.forEach(budgetItem => {
                if (!existingDates.has(budgetItem.Date)) {
                    data.dailyBudgets.push({ date: budgetItem.Date, budget: 0 });
                }
            });
        }

        submitOrder(cart.orderID, userId, data)
            .then(() => {
                setShowSuccessDialog(true)
                setIsOrderSubmitting(false)
            })

            .catch(error => {
                console.error(error)
                const errorMessage = error?.response?.data?.Message || error.message;

                setPaymentFailedText(t("order.error_window.failed_payment"))
                if (errorMessage === "Item is not available for ordering ") {
                    setPaymentFailedText(t("order.api_errors.failed_to_order_item_not_available"))
                }

                if (employeeChargeAmount) {
                    setIsPaymentFailed(true)
                } else {
                    if (errorMessage === "Item is not available for ordering ") {
                        dispatch(
                            errorActions.setError(error, t("order.api_errors.failed_to_order_item_not_available"))
                        )
                    } else {
                        dispatch(
                            errorActions.setError(error, t("order.api_errors.failed_to_order"))
                        )
                    }
                }
                setIsOrderSubmitting(false)

            })
            .catch(error => {
            })
    }

    function removeItem(index) {
        const item = orders.data[index - 1]
        deleteItem(userId, cart.orderID, item.Id)
            .then((data) => {
                setFetchSummaryCount(fetchSummaryCount + 1)
                dispatch(cartActions.decCount(userId, data.EmployeeSum))
            })
            .catch(error => {
                console.error(error)
                dispatch(
                    errorActions.setError(
                        error,
                        t("order.api_errors.failed_to_delete_from_cart")
                    )
                )
            })
    }

    function saveCard(creditCardToken, expirationMonth, expirationYear) {
        if (
            cards.find(el => el.creditCardToken === creditCardToken) === undefined
        ) {
            const newCard = {creditCardToken, expirationMonth, expirationYear}
            if (!isRoleDepartmentUser) {
                dispatch(cardActions.addCard(newCard))
            }
            cards.push(newCard)
            setSelectedCard(newCard)
            // setSendOrderCount(sendOrderCount + 1)
        }
    }

    function selectCard(card) {
        setSelectedCard(card)
        // setIsCvvInputInProgress(false)
    }

    function handleTogglePaymentMethod(state, id) {
        if (state) {
            setSelectedPaymentMethods([...selectedPaymentMethods, id])
        } else {
            setSelectedPaymentMethods(selectedPaymentMethods.filter(_id => _id !== id))
        }
    }

    function renderConfirmButton() {
        const isDisabled = isRoleDepartmentUser
            ? ""
            : orders.data?.length === 0
                ? "--disabled"
                : employeeChargeAmount && +selectedAmounts[4] !== 0 && !selectedCard.creditCardToken
                    ? "--disabled"
                    : ""

        const onClickAction = () => {
            if (isSelectedAmountInvalid || remainingAmount !== 0) {
                setShowAmountInvalidWindow(true)
                return
            }
            else{
                isRoleDepartmentUser ? setShowTranzilaWindow(true) : handleSendOrder()
            }
            //setShowConfirmationWindow(true)
        }


        /*const onClickAction = () =>
            isRoleDepartmentUser ? setShowTranzilaWindow(true) : handleSendOrder()*/

        if (width > 1023) {
            return (
                <a
                    onClick={onClickAction}
                    className={`confirm-order-button-desctop ${isDisabled}`}
                >
                    {t("order.confirm")}
                </a>
            )
        } else {
            return (
                <a
                    onClick={onClickAction}
                    className={`confirm-order-button ${isDisabled}`}
                    style={{bottom: `${footerHeight}px`}}
                >
                    {t("order.confirm")}
                </a>
            )
        }
    }

    useEffect(() => {
        if (cart && fetchSummaryCount && cart.count) {
            fetchSummary()
        }
    }, [cart, fetchSummaryCount])

    useEffect(() => {
        if (width < 1024 && footer.current) {
            setFooterHeight(footer.current.clientHeight)
        }
    }, [useCurrentWidth(), footer.current])

    useEffect(() => {
        props.setSearchUser({
            UserID: props.userInfo?.UserID,
            Name: `${props.userInfo?.FirstName} ${props.userInfo?.LastName}`
        })
    }, [props.adminUsers])

    if (isRoleAdmin && !selectedCustomer) {
        return <Redirect to="/main"/>
    }

    function handleGoBack() {
        props.history.go(-1)
    }

    function getDate() {
        const date = new Date();
        return date.toISOString().substring(0, 10);
    }
    return (
        <div className="order-page-wrapper">
            {editItem && (
                <div className="main-order-wrapper">
                    {
                        editItem.ComboID && editItem.ComboID !== '00000000-0000-0000-0000-000000000000' && (
                            <OrderComboDishPage
                                editData={editItem}
                                confirmEditOrder={() => setFetchSummaryCount(fetchSummaryCount + 1)}
                                submitData={data =>
                                    changeCombo(userId, cart.orderID, {
                                        ...editItem,
                                        Notes: data.notes,
                                        Items: data.items
                                    })
                                }
                                removeComboId={() => setEditItem(null)}
                                goToOrder={() => {
                                }}
                                comboID={editItem.ComboID}
                                mealID={editItem.MealID}
                                userID={userId}
                                date={editItem.OrderToDate}
                            />
                        )
                    }
                    {
                        editItem.ItemID  && editItem.ItemID !== '00000000-0000-0000-0000-000000000000' && (
                            <OrderDishPage
                                editData={editItem}
                                confirmEditOrder={() => setFetchSummaryCount(fetchSummaryCount + 1)}
                                submitData={data =>
                                    changeItem(userId, cart.orderID, {
                                        ...editItem,
                                        Notes: data.notes,
                                        SelectedOptions: data.options
                                    })
                                }
                                removeItemId={() => setEditItem(null)}
                                goToOrder={() => {
                                }}
                                itemID={editItem.ItemID}
                                mealID={editItem.MealID}
                                userID={userId}
                                date={editItem.selectedDate}
                            />
                        )
                    }

                </div>
            )}
            {isOrderSubmitting && (
                <div className="modal-overlay">
                    <LoadingPage/>
                </div>
            )}
            <div
                className={
                    editItem ? "order-wrapper hidden-on-mobile" : "order-wrapper"
                }
            >
                <NotifyWindow
                    show={showSuccessDialog}
                    primaryText={t("order.success_window.primary")}
                    confirmText={t("order.success_window.continue")}
                    onConfirm={() => {
                        dispatch(cartActions.clearCart(userId))
                        props.history.push("/main")
                    }}
                    onClose={() => {
                        dispatch(cartActions.clearCart(userId))
                        props.history.push("/main")
                    }}
                />
                <div className="error-wrapper">
                    <NotifyWindow
                        show={isPaymentFailed}
                        icon={warningIcon}
                        primaryText={paymentFailedText}
                        confirmText={t("order.error_window.continue")}
                        onConfirm={() => setIsPaymentFailed(false)}
                        onClose={() => setIsPaymentFailed(false)}
                    />
                    <NotifyWindow
                        show={showAmountInvalidWindow}
                        icon={warningIcon}
                        primaryText={
                            <Trans t={t} i18nKey="order.error_window.incorrect_amount">
                                You provided {{ selectedAmountsSum }}{t("currency")} needs to be {{ employeeChargeAmount }}{t("currency")}
                            </Trans>
                        }
                        confirmText={t("order.error_window.continue")}
                        onConfirm={() => setShowAmountInvalidWindow(false)}
                        onClose={() => setShowAmountInvalidWindow(false)}
                    />
                </div>
                <TranzilaWindow
                    show={showTranzilaWindow}
                    src={`https://direct.tranzila.com/${
                        process.env.REACT_APP_TRANZIL_TREMINAL
                    }/iframenew.php?hidesum=1&sum=1&currency=1&cred_type=1&lang=il&tranmode=VK&nologo=1&notify_url_address=${encodeURIComponent(
                        `${process.env.REACT_APP_BASE_PATH}/User/Orders/Notify`
                    )}&userId=${userInfo.UserID}&company=${encodeURIComponent(
                        !isRoleAdmin ? userInfo.Customer.Name : selectedCustomer.Name
                    )}&contact=${encodeURIComponent(userInfo.FirstName + " " + userInfo.LastName)}&email=${encodeURIComponent(
                        userInfo.Email
                    )}&phone=${userInfo.Mobile}`}
                    saveCard={saveCard}
                    onClose={() => setShowTranzilaWindow(false)}
                />
                <div className="order-title mobile-only">
                    <span>
                        <img src={cartIcon}/> {t("order.order")}
                    </span>
                    <span className='back' onClick={handleGoBack}/>
                </div>
                <OrderList
                    orders={orders}
                    deleteItem={removeItem}
                    setEditItem={setEditItem}
                    chargeAmount={employeeChargeAmount}
                    chargeAmountWithBudget={employeeChargeBudgetAmount}
                    hideTotal={hideTotal}
                ></OrderList>
                <OrderConfirmationWindow show={showConfirmationWindow}
                                         orderDate={getDate()}
                                         order={selectedAmounts}
                                         onConfirm={() => {
                                             setShowConfirmationWindow(false);
                                             isRoleDepartmentUser ? setShowTranzilaWindow(true) : handleSendOrder()
                                         }}
                                         onClose={() => setShowConfirmationWindow(false)}/>
                <div className="order-info">
                    <div className="desktop-only">
                        <div className="back-link">
                            <span onClick={handleGoBack}>{t("back")}</span>
                        </div>
                        <div className="order-title-desktop">
                            {t("order.order_completion")}
                        </div>
                    </div>
                    {employeeChargeAmount > 0 && (
                        <>
                            {!isRoleDepartmentUser && (
                                <div className="details-and-payments">
                                    <span>
                                        {t("order.choose_payment_method")}
                                    </span>
                                </div>
                            )}
                            <div className="card-selector">
                                {paymentOptions.filter(el => !el.isHidden).map((el, index) => 
                                    <PaymentAccordion
                                        key={el.id}
                                        data={el}
                                        selectedCard={selectedCard}
                                        onChangeCard={selectCard}
                                        selectedAmount={selectedAmounts[el.id]}
                                        onChangeAmount={handleChangeAmount}
                                        remainingAmount={remainingAmount}
                                        isRoleDepartmentUser={isRoleDepartmentUser}
                                        onTogglePaymentMethod={handleTogglePaymentMethod}
                                        isSelected={selectedPaymentMethods.includes(el.id)}
                                        isInvalid={isSelectedAmountInvalid}
                                        isHideBudget={el.isHideBudget}
                                    /> 
                                )}
                            </div>
                        </>
                    )}
                    {renderConfirmButton()}
                </div>
            </div>

            {width < 1024 ? <Footer reference={footer}/> :  <LogoFooter />}
        </div>
    )
}

function mapStateToProps(state) {
    return {
        userInfo: state.login.userInfo
    }
}

const mapDispatchToProps = {
    setSearchText,
    setSearchUser
}

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(OrderPage)
);
