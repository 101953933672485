import React from "react"
import ReactDOM from "react-dom"
import "./index.css"
import App from "./App"
import * as serviceWorker from "./serviceWorker"
import { I18nextProvider } from "react-i18next"
import i18next from "i18next"
import main_en from "./translations/en/main.json"
import main_he from "./translations/he/main.json"
import configureStore from "./redux/store"
import { Provider } from "react-redux"
import axios from "axios"
import { default as setupAxios } from "./redux/setupAxios"


i18next.init({
    interpolation: { escapeValue: false }, // React already does escaping
    lng: localStorage.getItem('lang') || (process.env.REACT_APP_APPLICATION_LANGUAGE || 'he'),
    resources: {
        en: {
            main: await processTranslations(main_en)
        },
        he: {
            main: await processTranslations(main_he)
        }
    }
});

function processTranslations(data) {
    function traverse(obj) {
        Object.keys(obj).forEach(key => {
            if (typeof obj[key] === 'object' && obj[key] !== null) {
                traverse(obj[key]);
            } else if (typeof obj[key] === 'string' && obj[key].startsWith('${') && obj[key].endsWith('}')) {
                let envVar = obj[key].slice(2, -1);
                if (envVar === 'CURRENCY') {
                    obj[key] = process.env.REACT_APP_FLAVOR === 'NY' ? '$' : '₪';
                }
            }
        });
    }

    traverse(data);
    return data;
}

const store = configureStore()

setupAxios(axios, store)

window.REACT_APP_FLAVOR = process.env.REACT_APP_FLAVOR;
const renderReactDom = () => {
  function getBrowser() {
    let ua = navigator.userAgent,
      tem,
      M =
        ua.match(
          /(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i
        ) || []
    if (/trident/i.test(M[1])) {
      tem = /\brv[ :]+(\d+)/g.exec(ua) || []
      return { name: "IE", version: tem[1] || "" }
    }
    if (M[1] === "Chrome") {
      tem = ua.match(/\bOPR\/(\d+)/)
      if (tem != null) {
        return { name: "Opera", version: tem[1] }
      }
    }
    if (window.navigator.userAgent.indexOf("Edge") > -1) {
      tem = ua.match(/\Edge\/(\d+)/)
      if (tem != null) {
        return { name: "Edge", version: tem[1] }
      }
    }
    M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, "-?"]
    if ((tem = ua.match(/version\/(\d+)/i)) != null) {
      M.splice(1, 1, tem[1])
    }
    return {
      name: M[0],
      version: +M[1]
    }
  }

  let isSupported = window.cordova ? true : isBrowserSupported()

  // Check if browser and version supported
  function isBrowserSupported() {
    const browser = getBrowser()
    let supported = true
    if (browser.name === "Chrome" && browser.version < 60) {
      supported = false
    } else if (browser.name === "Firefox" && browser.version < 63) {
      supported = false
    } else if (browser.name === "MSIE" || browser.name === "IE") {
      supported = false
    }
    return supported
  }

  ReactDOM.render(
    <React.StrictMode>
      <Provider store={store}>
        <I18nextProvider i18n={i18next}>
          <App isBrowserSupported={isSupported} />
        </I18nextProvider>
      </Provider>
    </React.StrictMode>,
    document.getElementById("root")
  )
}


if (window.cordova) {
  document.addEventListener(
    "deviceready",
    () => {
      console.log("Cordova start...." );
      OneSignalInit();
      renderReactDom();
    },
    false
  )
} else {
  console.log("web start...." );
  renderReactDom();
}

function OneSignalInit() {
     var oneSignalID = process.env.REACT_APP_ONESIGNAL_ID;
     console.log("One signal ID:" + oneSignalID);
    // Uncomment to set OneSignal device logging to VERBOSE  
     window.plugins.OneSignal.setLogLevel(6, 0);
    
    // NOTE: Update the setAppId value below with your OneSignal AppId.
    window.plugins.OneSignal.setAppId(oneSignalID);
    window.plugins.OneSignal.setNotificationOpenedHandler(function(jsonData) {
        console.log('notificationOpenedCallback: ' + JSON.stringify(jsonData));
    });
    
    // iOS - Prompts the user for notification permissions.
    //    * Since this shows a generic native prompt, we recommend instead using an In-App Message to prompt for notification permission (See step 6) to better communicate to your users what notifications they will get.
    window.plugins.OneSignal.promptForPushNotificationsWithUserResponse(function(accepted) {
        console.log("User accepted notifications: " + accepted);
    });
}

serviceWorker.unregister()
